import React from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Grid } from '@mui/material'
import Button from '../../atoms/Forms/Button/Button'
import bannerImage from '../../../assets/images/website/website6.jpg'
import radoscSeksu from '../../../assets/images/radosc_seksu.jpg'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Slider = (props) => {
	const t = LocalStorageHelper.get('translation')
	const style = {
		root: {
			background: 'rgb(247, 249, 252)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			position: 'relative',
			top: -20,
			// padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
			minHeight: 'calc(100vh - 200px)',
		},
		bigTitle: {
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '50px',
			lineHeight: BreakpointsHelper.isMobile() ? '24px' : '50px',
			fontWeight: '900',
			color: '#ce0707',
			textTransform: 'uppercase',
			maxWidth: '560px',
			textAlign: 'center',
		},
		childTitle: {
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '50px',
			lineHeight: BreakpointsHelper.isMobile() ? '24px' : '50px',
			fontWeight: '900',
			color: '#0788ce',
			textTransform: 'uppercase',
		},
		smallTitle: {
			marginTop: '10px',
			fontSize: '20px',
			lineHeight: '22px',
			fontWeight: '400',
			color: '#222325',
			maxWidth: '560px',
			textAlign: 'center',
		},
		image: {
			width: '100%',
		},
		item: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		center: {
			textAlign: 'center',
			margin: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: '100vh',
		},
	}

	return (
		<div style={style.root}>
			<Grid container spacing={2} style={style.container}>
				<Grid item md={6} xs={12} style={style.item}>
					<div style={style.bigTitle} className="animate__animated animate__fadeIn animate__slower">
						Życie jest jak <span style={style.childTitle}>morze</span>
					</div>
					<div style={style.smallTitle} className="animate__animated animate__fadeIn animate__slower animate__delay-1s">
						Piękne ale niebezpieczne, pełne wyzwań. Przewiń niżej i serfuj razem z nami w nadchodzących wydarzeniach.
					</div>
				</Grid>
				<Grid item md={6} xs={12}>
					<img src={bannerImage} className="animate__animated animate__fadeIn animate__slower" alt="banner image" style={style.image} />
				</Grid>
			</Grid>
		</div>
	)
}

export default Slider
